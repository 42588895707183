import { AuthConfig } from 'angular-oauth2-oidc';

export const authCodeFlowConfig: AuthConfig = {
  clientId: 'cc24-ceo-leaderboard',
  scope: 'openid profile email roles read offline_access',
  responseType: 'code',
  oidc: true,
  skipIssuerCheck: true,
  clockSkewInSec: 10,
  timeoutFactor: 0.75,
};

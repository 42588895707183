@if (!loggedIn) {
<div class="loding-container">
  <mat-spinner></mat-spinner>
</div>
}@else {
<mat-toolbar>
  <div class="cc-toolbar">
    <img src="/assets/img/cc24_logo.svg" alt="" width="100px" />
    <mat-icon
      (click)="logout()"
      aria-hidden="false"
      fontIcon="logout"
    ></mat-icon>
  </div>
</mat-toolbar>
<router-outlet></router-outlet>
}

import { Routes } from '@angular/router';
import { LeaderboardListComponent } from './components/leaderboard-list/leaderboard-list.component';
import { inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

const authenticationGuardArrow = async () => {
  const oauthService = inject(OAuthService);
  if (oauthService.hasValidAccessToken() && oauthService.hasValidIdToken()) {
    return true;
  } else {
    const currentUrl = window.location.pathname + window.location.search;
    return oauthService.loadDiscoveryDocumentAndLogin({
      ...(currentUrl !== '/' && { state: currentUrl }),
    });
  }
};

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'home',
    component: LeaderboardListComponent,
    canActivate: [authenticationGuardArrow],
  },
];

import {
  APP_INITIALIZER,
  ApplicationConfig,
  inject,
  isDevMode,
} from '@angular/core';
import { provideRouter, Router } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideServiceWorker } from '@angular/service-worker';
import {
  HTTP_INTERCEPTORS,
  HttpInterceptorFn,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  DefaultOAuthInterceptor,
  OAuthModuleConfig,
  OAuthResourceServerErrorHandler,
  OAuthService,
  OAuthStorage,
  provideOAuthClient,
} from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './oauth.config';
import { environment } from '../environments/environment';
import { ErrorInterceptor } from './interceptors/error.interceptor';

function initializeAppFactory(
  oauthService: OAuthService,
  router: Router
): () => Promise<void> {
  return async () => {
    const config = {
      ...authCodeFlowConfig,
      redirectUri: environment.redirectUri,
      issuer: environment.issuer,
    };
    oauthService.configure(config);
    const currentUrl = window.location.pathname + window.location.search;
    await oauthService.loadDiscoveryDocumentAndLogin({
      ...(currentUrl !== '/' && { state: currentUrl }),
    });
    if (!!oauthService?.state) {
      router.navigateByUrl('/' + decodeURIComponent(oauthService.state));
    }
    oauthService.setupAutomaticSilentRefresh();
    return;
  };
}

export const bearerTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authStorage = inject(OAuthStorage);
  const reqWithToken = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authStorage.getItem('access_token')}`,
    },
  });

  return next(reqWithToken);
};
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([bearerTokenInterceptor])),
    provideOAuthClient({
      resourceServer: {
        allowedUrls: ['https://high.ddnss.de/api'],
        sendAccessToken: true,
      },
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [OAuthService, Router],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultOAuthInterceptor,
      deps: [OAuthService, OAuthResourceServerErrorHandler, OAuthModuleConfig],
      multi: true,
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};

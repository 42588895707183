export const CURRENT_DAY = new Date().toISOString().slice(0, 10) + 'T00:00:00';
export const CURRENT_MONTH = new Date().getMonth() + 1;
export const CURRENT_YEAR = new Date().getFullYear();

export const CURRENT_WEEK = () => {
  const adjustedDate = new Date(new Date().getFullYear(), 0, 0); // Set to Jan 1st
  adjustedDate.setDate(
    adjustedDate.getDate() + 4 - (adjustedDate.getDay() || 7)
  );
  return Math.ceil(
    (new Date().getTime() - adjustedDate.getTime()) / 86400000 / 7
  );
};

<div class="page">
  <div class="summary">
    @if(summary$ | async ; as summary) { @for (item of summary; track $index) {
    <mat-card appearance="outlined">
      <mat-card-content>
        <p style="color: gray">{{ item.lable }}</p>
        <p style="font-size: 25px">{{ item.value }}</p>
      </mat-card-content>
    </mat-card>
    } }
  </div>
  <div class="board">
    @for (user of dailyLeaderboard$ |async; track $index;let index = $index) {
    @defer (on viewport) {
    <mat-card appearance="outlined" (click)="open(user.id)">
      @if (user.partialResults.dailySales !== 0) {
      <span
        [ngClass]="{
              'badge-gold': user.topPlacement ===1,
              'badge-silver': user.topPlacement ===2,
              'badge-bronze': user.topPlacement ===3,
              'badge': user.topPlacement >3 ,
            }"
      >
        {{ user.partialResults.dailySales }}
      </span>
      }
      <mat-card-header>
        <div mat-card-avatar class="example-header-image">
          <img
            width="50px"
            imageLoader
            [profileUrl]="user.imageReference"
            [iconUrl]="user.iconUrl"
            alt="Avatar"
          />
        </div>
        <mat-card-title>
          <div class="title">
            <span> {{ index + 1 }}. {{ user.name }} </span>
          </div>
        </mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        @if (setOfOpenedId.has(user.id)) {
        <div class="scores">
          @for (weeklySale of user.partialResults.weeklySales; track $index) {
          <span> KW{{ weeklySale.week }} - {{ weeklySale.count }} </span>
          }
        </div>
        <mat-divider></mat-divider>
        <div class="scores">
          <span> M - {{ user.partialResults.monthlySales }} </span>
          <span> Y - {{ user.partialResults.yearlySales }}</span>
        </div>
        }
      </mat-card-content>
    </mat-card>
    } @placeholder {
    <p>loading...</p>
    } }
  </div>
</div>

import { Component, OnInit, inject } from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { LeaderboardService } from '../../services/leaderboard.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { BehaviorSubject, combineLatest, forkJoin, map } from 'rxjs';
import {
  ISale,
  IUser,
  IUserWithSales,
  IUserWithSalesWithResults,
} from '../../interfaces/all.interface';
import { CURRENT_MONTH, CURRENT_WEEK, CURRENT_DAY, CURRENT_YEAR } from './util';
import { ImageLoaderDirective } from '../../directives/image-loader.directive';
@Component({
  selector: 'app-leaderboard-list',
  templateUrl: './leaderboard-list.component.html',
  styleUrls: ['./leaderboard-list.component.scss'],
  standalone: true,
  providers: [LeaderboardService],
  imports: [
    MatCardModule,
    AsyncPipe,
    MatIconModule,
    MatDividerModule,
    MatBadgeModule,
    NgClass,
    ImageLoaderDirective,
  ],
})
export class LeaderboardListComponent implements OnInit {
  private leaderboardService = inject(LeaderboardService);
  private users = new BehaviorSubject<IUser[]>([]);
  private sales = new BehaviorSubject<ISale[]>([]);
  private users$ = this.users.asObservable();
  private sales$ = this.sales.asObservable();

  setOfOpenedId = new Set<string>();

  dailySales$ = this.sales$.pipe(
    map((sales) => {
      return sales.filter((sale) => sale.date === CURRENT_DAY).length;
    })
  );

  weeklySales$ = this.sales$.pipe(
    map((sales) => {
      return sales.filter((sale) => sale.calendarWeek === CURRENT_WEEK())
        .length;
    })
  );

  monthlySales$ = this.sales$.pipe(
    map((sales) => {
      return sales.filter((sale) => sale.month === CURRENT_MONTH).length;
    })
  );

  yearlySales$ = this.sales$.pipe(
    map((sales) => {
      return 10000 - sales.filter((sale) => sale.year === CURRENT_YEAR).length;
    })
  );

  summary$ = combineLatest([
    this.dailySales$,
    this.weeklySales$,
    this.monthlySales$,
    this.yearlySales$,
  ]).pipe(
    map(([dailySales, weeklySales, monthlySales, yearlySales]) => {
      return [
        {
          lable: 'Daily ',
          value: dailySales,
        },
        {
          lable: 'Weekly ',
          value: weeklySales,
        },
        {
          lable: 'Monthly ',
          value: monthlySales,
        },
        {
          lable: 'Sales Goal',
          value: yearlySales,
        },
      ];
    })
  );

  dailyLeaderboard$ = combineLatest([this.users$, this.sales$]).pipe(
    map(([users, sales]) => {
      const allSalesByUserName = this.groupSalesByUser(sales);
      const usersWithSales = this.addSalesToUsers(users, allSalesByUserName);
      const usersWithSalesWithPartialResults =
        this.addPartialResultsToUsers(usersWithSales);
      const orderedByDailySales = usersWithSalesWithPartialResults.sort(
        (a, b) => b.partialResults.dailySales - a.partialResults.dailySales
      );
      return this.addTopPlacementToUsers(orderedByDailySales);
    })
  );

  addTopPlacementToUsers(orderedByDailySales: IUserWithSalesWithResults[]) {
    const addedTopPlacement = [];
    for (let i = 0; i < orderedByDailySales.length; i++) {
      const element = orderedByDailySales[i];
      if (i === 0) {
        addedTopPlacement.push({ ...element, topPlacement: 1 });
      }
      if (i > 0) {
        addedTopPlacement.push({
          ...element,
          topPlacement: this.calculateTopPlacement(
            addedTopPlacement[i - 1],
            element
          ),
        });
      }
    }
    return addedTopPlacement;
  }
  addPartialResultsToUsers(
    usersWithSales: IUserWithSales[]
  ): IUserWithSalesWithResults[] {
    return usersWithSales.map((user) => {
      const userSales = user.sales;
      return {
        ...user,
        partialResults: {
          dailySales: this.getSalesCountByDay(userSales, CURRENT_DAY),
          weeklySales: [
            {
              week: CURRENT_WEEK(),
              count: this.getSalesCountByWeek(userSales, CURRENT_WEEK()),
            },
            {
              week: CURRENT_WEEK() - 1,
              count: this.getSalesCountByWeek(userSales, CURRENT_WEEK() - 1),
            },
            {
              week: CURRENT_WEEK() - 2,
              count: this.getSalesCountByWeek(userSales, CURRENT_WEEK() - 2),
            },
            {
              week: CURRENT_WEEK() - 3,
              count: this.getSalesCountByWeek(userSales, CURRENT_WEEK() - 3),
            },
          ],
          monthlySales: this.getSalesCountByMonth(userSales, CURRENT_MONTH),
          yearlySales: this.getSalesCountByYear(userSales, CURRENT_YEAR),
        },
      };
    });
  }
  calculateTopPlacement(lastUser: any, currentuser: IUserWithSalesWithResults) {
    if (
      lastUser.partialResults.dailySales ===
      currentuser.partialResults.dailySales
    ) {
      return lastUser.topPlacement;
    }
    if (
      lastUser.partialResults.dailySales > currentuser.partialResults.dailySales
    ) {
      return lastUser.topPlacement + 1;
    }
  }
  getSalesCountByDay(sales: ISale[], day: string) {
    return sales.filter((sale) => sale.date === day).length;
  }
  getSalesCountByWeek(sales: ISale[], week: number) {
    return sales.filter(
      (sale) => sale.calendarWeek === week && sale.year === CURRENT_YEAR
    ).length;
  }
  getSalesCountByMonth(sales: ISale[], month: number) {
    return sales.filter((sale) => sale.month === month).length;
  }
  getSalesCountByYear(sales: ISale[], year: number) {
    return sales.filter((sale) => sale.year === year).length;
  }
  groupSalesByUser(sales: ISale[]) {
    return sales.reduce<Record<string, ISale[]>>((acc: any, sale) => {
      if (!acc[sale.userId]) {
        return { ...acc, [sale.userId]: [{ ...sale }] };
      } else {
        return { ...acc, [sale.userId]: [...acc[sale.userId], { ...sale }] };
      }
    }, {});
  }
  addSalesToUsers(users: IUser[], sales: Record<string, ISale[]>) {
    return users.map<IUserWithSales>((user: any) => ({
      ...user,
      sales: sales[user.id] || [],
    }));
  }
  open(index: string) {
    if (!this.setOfOpenedId.has(index)) {
      this.setOfOpenedId.add(index);
    } else {
      this.setOfOpenedId.delete(index);
    }
  }

  ngOnInit() {
    this.loadData();
    setInterval(() => {
      this.loadData();
    }, 60000);
  }

  private loadData() {
    forkJoin([
      this.leaderboardService.getSales(),
      this.leaderboardService
        .getUser()
        .pipe(map((users) => users.filter((user) => user.display))),
    ]).subscribe(([sales, users]) => {
      this.sales.next(sales);
      this.users.next(users);
    });
  }
}
